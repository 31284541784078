const initialState = { idee: [] }
function saveIdee  (state = initialState, action) {
  let nextState
  switch (action.type) {
    case 'SAVE_IDEE':
      nextState = {
        ...state,
        idee: action.value
    }
    return nextState
  default:
    return state
  }
}
export default saveIdee