import React, {Component} from 'react';
import './App.css';
import Accueil from './Components/Accueil/Accueil';
import Article_accueil from './Components/Actualite/Article_accueil';
import Idee from './Components/Idees/Idee';
import Participer from './Components/Idees/Participer/Participer';
import ParticiperProjet from './Components/Appel_projet/Participer/ParticiperProjet';
import Ajout_idee from './Components/Ajouter_idee/Ajout_idee'
import AppelProjet from './Components/Appel_projet/AppelProjet'
import Projet from './Components/Appel_projet/Projet'
import NotFound from './Components/404';
import Connexion from './Components/Connexion/Connexion';
import Etude from "./Components/Idees/Etude"
import Realisation from "./Components/Idees/Realisation"
import Actualite from "./Components/Actualite/Actualite"
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import {Provider} from 'react-redux'
import Store from './Store/configureStore'
import NavBar from './Components/NavBar/NavBar';
import FooterReact from './Components/Footer/Footer';
import FooterImg from './Components/Footer/FooterImg';
import ScrollToTop from "./Components/ScrollToTop";
import ScrollButton from "./Components/ScrollButton";

class App extends Component {
  render() {
    return (
      <Provider store={Store}> 
        <Router>
          <NavBar/>
          <ScrollToTop/>
            <Switch>
              <Route exact path="/" component ={Accueil}/> 
              <Route exact path="eriliab.erilia.fr" component ={Accueil}/> 
              <Route exact path="/actualite" component ={Actualite}/>
              <Route exact path="/article" component ={Article_accueil}/>
              <Route exact path="/idee" component ={Idee}/>
              <Route exact path="/participer_idee" component ={Participer}/>
              <Route exact path="/participer_projet" component ={ParticiperProjet}/>
              <Route exact path="/ajouter_idee" component ={Ajout_idee}/>
              <Route exact path="/connexion" component ={Connexion}/>
              <Route exact path="/liste_projet" component ={AppelProjet}/>
              <Route exact path='/etude' component={Etude} />
              <Route exact path='/realisation' component={Realisation} />
              <Route exact path="/404" component ={NotFound}/>
              <Route exact path="/projet" component ={Projet}/>
              <Redirect to="/404"/>
            </Switch>
            <ScrollButton/>
            <FooterImg/>
          <FooterReact/> 
        </Router>
      </Provider>
    )
  }
}
export default App