import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./Navbar.css"
import MenuButton from'./MenuButton';
import { Link } from "react-router-dom";
import NavDropdown from 'react-bootstrap/NavDropdown'
import { gapi } from 'gapi-script'
import { connect } from 'react-redux';

const SPREADSHEETS_ID = "12A7ifFUZUuw3Bw1sQvUnM_enV5vg_QEv91Rp3iUxZHM";
class NavbarBootstrap extends React.Component {
  constructor() {
    super()
      this.listProjet = this.listProjet.bind(this)
      this.listEtude = this.listEtude.bind(this)
      this.listRealisation = this.listRealisation.bind(this);
    }

  listProjet() {
    gapi.client.sheets.spreadsheets.values.get({
    spreadsheetId: SPREADSHEETS_ID,
    range: 'Appel a projet!A3:F100'
   }).then((response) => {
    var result = response.result;
    const action = { type: "SAVE_PROJET", value:result.values};
    this.props.dispatch(action); 
   });

   gapi.client.sheets.spreadsheets.values.get({
    spreadsheetId: SPREADSHEETS_ID,
    range: 'Appel a projet!B2:B2'
   }).then((response) => {
    var result = response.result;
    const action = { type: "SAVE_PROJET_PHRASE", value:result.values};
    this.props.dispatch(action); 
   });
  }

  listEtude() {
    gapi.client.sheets.spreadsheets.values.get({
    spreadsheetId: SPREADSHEETS_ID,
    range: 'Etude!A3:F100'
   }).then((response) => {
    var result = response.result;
    const action = { type: "SAVE_ETUDE", value:result.values};
    this.props.dispatch(action); 
   });

   gapi.client.sheets.spreadsheets.values.get({
    spreadsheetId: SPREADSHEETS_ID,
    range: 'Etude!B2:B2'
   }).then((response) => {
    var result = response.result;
    const action = { type: "SAVE_ETUDE_PHRASE", value:result.values};
    this.props.dispatch(action); 
   });
  }
  
  listRealisation() {
    gapi.client.sheets.spreadsheets.values.get({
    spreadsheetId: SPREADSHEETS_ID,
    range: 'Realisation!A2:F100'
   }).then((response) => {
    var result = response.result;
    const action = { type: "SAVE_REALISATION", value:result.values};
    this.props.dispatch(action); 
   });
  }
render() {
  return (
    <div>
      <nav className="navbar default fixed-top navbar-expand-lg navbar-dark primary-color ">
        <div className="toolbar_toggle_button">
          <MenuButton click={this.props.ToggleMenuClickHandler}/>
        </div>
          <div class="d-md-flex d-block flex-row- mx-md-auto mx-0 ">
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav mr-auto"> 
                <li className="nav-item">
                  <Link to="/" className="nav-link">Accueil</Link>
                </li>
                <li className="nav-item">
                  <Link to="/liste_projet" className="nav-link" onClick={this.listProjet}>Appel à projet</Link>
                </li>
                <li className="nav-item">
                <NavDropdown title="Les idées" id="nav-dropdown" className="nav-item" active>
                  <li><Link to="/etude" className="dropdown-item" onClick={this.listEtude}>Etude</Link></li>
                  <li><Link to="/realisation" className="dropdown-item" onClick={this.listRealisation}>En cours de réalisation</Link></li>
                </NavDropdown>
                </li>
              </ul>
            </div>
          </div>
      </nav>
    </div>
    ); 
  }  
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps)(NavbarBootstrap)