const initialState = { projet: [], projetPhrase: [] }
function saveProjet (state = initialState, action) {
  let nextState
  switch (action.type) {
    case 'SAVE_PROJET':
      nextState = {
        ...state,
        projet: action.value
    }
    return nextState  

    case 'SAVE_PROJET_PHRASE':
      nextState = {
        ...state,
        projetPhrase: action.value
    }
    return nextState  

  default:
    return state
  }
}
export default saveProjet