import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Card from 'react-bootstrap/Card'
import {Link, Redirect} from "react-router-dom";
import {connect} from 'react-redux';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import pasImage from './pasImage.jpg';
import './Actualite.css'
import FloatingButton from "../Ajouter_idee/FloatingButton"

class Actualite extends React.Component{
  constructor() {
    super()
      this.gotoidee = this.gotoidee.bind(this);
        this.state = {
          linkimg:"https://drive.google.com/uc?export=view&id=",
        }
      }
gotoidee (e)  {
  const action = { type: "SAVE_ARTICLE", value:this.props.actualite[e.target.id]};
  this.props.dispatch(action); 
}

render() {
  if(this.props.logged !== true) {
    return (<Redirect to="/connexion" />);
  } 
  let data;
  if(this.props.actualite !== undefined) {
                
      data = this.props.actualite.map((list,idIdee) => { 
        let img;
        if(list[4] === undefined || list[4] === "") {
          img = pasImage;
        }
        else {
          img = this.state.linkimg + list[4]
        }
      return (
        <Col className="position">
          <Card>
            <Card.Header style={{backgroundColor: "rgb(8, 102, 102)", color: "white", fontWeight: "bold", textAlign: "center"}}>{list[0]}</Card.Header>
              <Card.Img top width="100%" src={img}/>
                <Card.Body >
                  <Card.Text>{list[2]}</Card.Text>
                  <Card.Text>{list[3]}</Card.Text>
                    <Link to="/article">
                      <Button type="submit" id={idIdee} onClick={this.gotoidee} variant="link">En savoir plus</Button>
                    </Link>
                </Card.Body>
          </Card>
        </Col>
        
        )
      }) 
}
else {
    data =  <Col className="position">
              <Card>
                <Card.Header style={{backgroundColor: "rgb(8, 102, 102)", color: "white", fontWeight: "bold", textAlign: "center"}}>Aucune actualité</Card.Header>
                  <Card.Img top width="100%" src={pasImage}/>
                </Card>
            </Col>
}
  return (
    <div className="content">
      <div className="etude-css">
        <h1 className="listeIdee">Actualités</h1>
            <Container fluid>
              <Row lg="5" md="1" sm="1" xs="1" className="row justify-content-md-center">
                {data}
              </Row>
            </Container>
          <FloatingButton/>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    logged: state.saveUser.logged,
    actualite: state.saveActualite.actualite
  }
}
export default connect(mapStateToProps)(Actualite)