const initialState = { realisation: [] }
function saveRealisation  (state = initialState, action) {
  let nextState
  switch (action.type) {
    case 'SAVE_REALISATION':
      nextState = {
        ...state,
        realisation: action.value
    }
    return nextState
  default:
    return state
  }
}
export default saveRealisation