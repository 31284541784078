import React from 'react';
import './Connexion.css';
import GoogleLogin from 'react-google-login';
import Form from 'react-bootstrap/Form'
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux'
import { gapi } from 'gapi-script'

const CLIENT_ID = "86497545424-79s6djignsrgmjta71kuo7b2ng80229r.apps.googleusercontent.com";
class connexion extends React.Component {  
    initClient =() => {
        gapi.client.init({
            'apiKey' : 'AIzaSyDCo7A5JzJVCC6wnxBBkGMaj5RBH5USAaw',
            'clientId' : CLIENT_ID,
            'scope' : 'https://www.googleapis.com/auth/spreadsheets',
            'discoveryDocs' : ['https://sheets.googleapis.com/$discovery/rest?version=v4'],
        });
    } 
    responseGoogle = (response) =>{  
        this.setState({isLoggedIn :true});
        const save_name = { type: "SAVE_USER", value: response.profileObj.name}
        this.props.dispatch(save_name)
        const save_login = { type: "SAVE_LOGIN", value: true}
        this.props.dispatch(save_login)
        const save_mail = { type: "SAVE_MAIL", value: response.profileObj.email}
        this.props.dispatch(save_mail)
        const save_photo = { type: "SAVE_PHOTO", value: response.profileObj.imageUrl}
        this.props.dispatch(save_photo)
        this.props.history.push('/'); 
        gapi.load('client:auth2', this.initClient); 
    }
    error = (response) =>{
    //    console.log("ERREUR" + response);
    }
    
render() {
    if(this.props.logged === true) {
        return (<Redirect to="/"/>);
    }
return (
    <div className="content">
        <div className="login">
            <Form>
                <h1 className="title">Se connecter avec Google</h1> 
                    <GoogleLogin
                        clientId="86497545424-79s6djignsrgmjta71kuo7b2ng80229r.apps.googleusercontent.com"
                        buttonText="Se connecter"
                        disabled={false}
                        onSuccess={this.responseGoogle}
                        scope="https://www.googleapis.com/auth/spreadsheets"
                        isSignedIn={true}
                        onFailure={this.error}
                        cookiePolicy={'single_host_origin'}   
                        responseType={'id_token'}                  
                    />
            </Form>
        </div>
    </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
      nom: state.saveUser.nom,
      logged: state.saveUser.logged,
      mail: state.saveUser.mail,
      photo: state.saveUser.photo
    }
  }
export default connect(mapStateToProps)(connexion)