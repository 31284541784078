const initialState = { articleProjet: [] }
function saveArticleProjet (state = initialState, action) {
  let nextState
  switch (action.type) {
    case 'SAVE_ARTICLE_PROJET':
      nextState = {
        ...state,
        articleProjet: action.value
    }
    return nextState
  default:
    return state
  }
}
export default saveArticleProjet