const initialState = { nom: [], logged: [],mail: [],photo: [] }

const saveUser = (state = initialState, action) => {
  let nextState
  switch (action.type) {
    case 'SAVE_USER':
      nextState = {
        ...state,
        nom: action.value
    }
    return nextState
    
    case 'SAVE_LOGIN':
      nextState = {
        ...state,
        logged: action.value
    }    
    return nextState

    case 'SAVE_MAIL':
      nextState = {
        ...state,
        mail: action.value
    }
    return nextState
    
    case 'SAVE_PHOTO':
      nextState = {
        ...state,
        photo: action.value
    }
    return nextState

  default:
    return state
  }
}
export default saveUser