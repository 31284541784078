import React from "react";

class NotFound extends React.Component {
    render() {
        return(
            <div className="pageError">
                <h2>La page demandée n'existe pas.</h2>
            </div>
        )
    }
}
export default NotFound;