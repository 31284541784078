import React from "react";
import "./Actualite.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Redirect } from "react-router-dom";
import { connect } from 'react-redux'
import FloatingButton from "../Ajouter_idee/FloatingButton"

class article extends React.Component{
    constructor() {
        super()
          this.state = {
            data: [],
            linkimg:"https://drive.google.com/uc?export=view&id="
          }
        }
render() {
let imgArticle=this.state.linkimg + this.props.article[4];
  if(this.props.logged !== true) {
    return (<Redirect to="/connexion" />);
  }  
    return(   
        <div className="content">
          <div className="article"> 
            <div class="jumbotron hoverable p-20 bg-white">
              <div class="row" style={{width:"90%",marginLeft:"5%"}}>
                <div class="col-md-4 offset-md-1 mx-3 my-3">
                  <div class="view overlay">
                    <img src={imgArticle} class="img-fluid" alt="article"></img>
                  </div>
                </div>
                  <div class="col-md-7 text-md-left ml-3 mt-3 ">
                    <h4 class="h4 mb-4">{this.props.article[0]}</h4>
                      <p class="text-justify" style={{whiteSpace: "pre-line"}}>{this.props.article[1]}</p>
                        <p class="font-weight-normal"><strong>{this.props.article[2]}</strong> {this.props.article[3]}</p>
                  </div>
              </div>
            </div>
          </div>                  
          <FloatingButton/>  
        </div>
        )
    }   
};
const mapStateToProps = (state) => {
  return {
    logged: state.saveUser.logged,
    article: state.saveArticle.article
  }
}
export default connect(mapStateToProps)(article)