import React from 'react';
import Form from 'react-bootstrap/Form'
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import './Ajout_idee.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { gapi } from 'gapi-script';
import Connexion from '../Connexion/Connexion';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)
const SPREADSHEETS_ID = "12A7ifFUZUuw3Bw1sQvUnM_enV5vg_QEv91Rp3iUxZHM";

class add extends React.Component{
    constructor(props) {
        super(props);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.state = {
            title:"",
            descr:"",
            date: new Date().toLocaleDateString()
          }
    }

onFormSubmit(e) {
    const params = {
        spreadsheetId: SPREADSHEETS_ID,
        range: 'Proposition idees',
        valueInputOption: 'RAW',
        insertDataOption: 'INSERT_ROWS',
    };        
    const valueRangeBody = {
        'majorDimension': 'ROWS', 
        'values': [[this.state.title,this.state.descr,this.props.nom,this.props.mail,this.state.date]]  
    };
    let request = gapi.client.sheets.spreadsheets.values.append(params, valueRangeBody);
    request.then(function () {
       MySwal.fire(
        'Succès !',
        'Votre idée a été envoyée !',
        'success'
      )
    }, function (reason) {
        MySwal.fire({
            icon: 'error',
            title: 'Erreur !',
            text: 'Une erreur est survenue !',
          })
      //  console.error('Erreur' + reason.result.error.message);
    });
    e.preventDefault();
    this.props.history.push('/'); 
}
handleTitleChange = (e) => {
    this.setState({ title: e.target.value });
}
handleDescrChange = (e) => {
    this.setState({ descr: e.target.value });
}

render() {
    if(this.props.logged !== true) {
        return(<Connexion/>)
    }
    
    return (
    <div className="content">
        <div className="ajoutcorps">
            <Form onSubmit={this.onFormSubmit}>
                <h1>Ajouter une idée</h1>
                    <Form.Group controlId="formIdeeTitre" className="partie">
                        <Form.Label >Titre de l'idée</Form.Label>
                        <Form.Control required type="text" placeholder="Titre de votre idée" onChange={this.handleTitleChange}/>
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword" className="partie">
                        <Form.Label>Description de votre idée</Form.Label>
                        <Form.Control as="textarea" placeholder="Description" rows="5" required onChange={this.handleDescrChange}/>
                    </Form.Group>
                    <Button type="submit" className="boutonadd">Envoyer mon idée</Button>
            </Form>
        </div> 
    </div>
        )  
    } 
};
const mapStateToProps = (state) => {
    return {
      logged: state.saveUser.logged,
      nom: state.saveUser.nom,
      mail: state.saveUser.mail,
    }
  }
export default withRouter(connect(mapStateToProps)(add))