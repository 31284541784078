import React from "react";
import "./Navbar.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from "react-router-dom";
import { connect } from 'react-redux'
import logoAppli from "./logoAppli.png"

class Header extends React.Component{
render() {
  let header;
  if (this.props.photo === "" ){
      header = ""
  }
    else if (this.props.photo === undefined) {
      header = ""
    }    
    else if(this.props.logged === true){
      header = <img src={this.props.photo} class="rounded-circle z-depth-0" alt="avatar" height="25"></img>
    }
    return(
       <div>
          <nav class="navbar navbar-light fixed-top bg-white">
            <Link to="/" class="navbar-brand">
              <img src={logoAppli}  alt="" className="header-img"/>
                ERILIAB
            </Link>
            <div className="header-google">
              <a href="https://mail.google.com/mail/u/0/#inbox" target="_blank" rel="noopener noreferrer" className="header-nom">
                {this.props.nom}
              </a> 
                  {header}
            </div>
          </nav>
        </div>
        )
    }   
};
const mapStateToProps = (state) => {
  return {
    logged: state.saveUser.logged,
    nom: state.saveUser.nom,
    photo: state.saveUser.photo
  }
}
export default connect(mapStateToProps)(Header)