const initialState = { etude: [], etudeP:[] }
function saveEtude (state = initialState, action) {
  let nextState
  switch (action.type) {
    case 'SAVE_ETUDE':
      nextState = {
        ...state,
        etude: action.value
    }
    return nextState 

    case 'SAVE_ETUDE_PHRASE':
      nextState = {
        ...state,
        etudeP: action.value
    }
    return nextState

  default:
    return state
  }
}
export default saveEtude