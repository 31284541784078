import React, {Component} from 'react';
import NavbarBootstrap from './NavbarBootstrap';
import Menu from './Menu';
import BackDrop from './BackDrop';
import Header from './Header';

class NavBar extends Component {
  state = {
    OpenMenu: false,
  }

  ToggleMenuClickHandler = () => {
    this.setState(prevState => {
      return { OpenMenu: !prevState.OpenMenu }
    })
  }
  backdropClickHandler = () => {
    this.setState({ OpenMenu: false })
  }
  render() {
    let backdrop;
    if (this.state.OpenMenu) {
      backdrop = <BackDrop click={this.backdropClickHandler} />
    }
    return (
      <div >
        <Header/>
        <NavbarBootstrap ToggleMenuClickHandler={this.ToggleMenuClickHandler}/>
        <Menu show={this.state.OpenMenu}/>
        {backdrop}
      </div>
    )
  }
}
export default NavBar