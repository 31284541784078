import React from 'react';
import Form from 'react-bootstrap/Form'
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import '../Idee.css';
import { connect } from 'react-redux'
import { gapi } from 'gapi-script'
import { withRouter, Redirect } from 'react-router-dom';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const SPREADSHEETS_ID = "12A7ifFUZUuw3Bw1sQvUnM_enV5vg_QEv91Rp3iUxZHM";
const MySwal = withReactContent(Swal)

class participer extends React.Component{
    constructor(props) {
        super(props);
            this.onFormSubmit = this.onFormSubmit.bind(this);
                this.state = {
                descr:"",
                date: new Date().toLocaleDateString(),
            }
        }

onFormSubmit(e) {
    const params = {
        spreadsheetId: SPREADSHEETS_ID,
        range: 'Participer Idee',
        valueInputOption: 'RAW',
        insertDataOption: 'INSERT_ROWS',
    };
    const valueRangeBody = {
        'majorDimension': 'ROWS',
        'values': [[this.props.idee[0],this.state.descr,this.props.nom,this.props.mail,this.state.date]]
    };
    let request = gapi.client.sheets.spreadsheets.values.append(params, valueRangeBody);
    request.then(function () {
        MySwal.fire(
            'Succès',
            'Votre participation a été envoyée !',
            'success'
          )
    }, function (reason) {
        MySwal.fire({
            icon: 'error',
            title: 'Erreur !',
            text: 'Une erreur est survenue !',
          })
      //  console.error('Erreur' + reason.result.error.message);
    }); 
    e.preventDefault()
    this.props.history.push('/idee');
}

handleDescrChange = (e) => {
    this.setState({ descr: e.target.value });
}
render() {
    if(this.props.logged !== true) {
        return(<Redirect to="/connexion" />)
    }
    return (
    <div className="content">    
        <div className="corps">
            <Form onSubmit={this.onFormSubmit}>
                <h1>Participer à l'idée</h1>
                    <Form.Group controlId="formIdeeTitre" className="partie">
                        <Form.Label>Titre de l'idée</Form.Label>
                        <Form.Control type="text" placeholder="Titre de votre idée" value={this.props.idee[0]} disabled/>
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword" className="partie">
                        <Form.Label>Décrivez votre participation</Form.Label>
                        <Form.Control as="textarea" placeholder="Description" rows="5" required onChange={this.handleDescrChange}/>
                    </Form.Group>
                    <Button type="submit" className="boutonparticiper"> Envoyer ma participation</Button>    
            </Form>
        </div>
    </div>
        )  
    } 
};
const mapStateToProps = (state) => {
    return {
      logged: state.saveUser.logged,
      nom: state.saveUser.nom,
      mail: state.saveUser.mail,
      idee: state.saveIdee.idee
    }
  }
export default withRouter(connect(mapStateToProps)(participer))