import React from "react";
import "./Footer.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import erilialogo from "./erilialogo.png"

class footerImg extends React.Component {
render() {
return(
  <div className="footer-img">
    <img src={erilialogo} className="footer-img" alt="footer logo"/>
  </div>
    )
  }
};
export default footerImg