import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import pasImage from './pasImage.jpg';
import "./Idee.css";
import FloatingButton from "../Ajouter_idee/FloatingButton"

class article extends React.Component{
  constructor (props) {
		super (props)
		  this.state ={
        linkimg:"https://drive.google.com/uc?export=view&id=",
      }
    }
    
render() {
  if(this.props.logged !== true) {
    return (<Redirect to="/connexion" />);
  }  

  let img;
    if(this.props.idee[4] === undefined || this.props.idee[4] === "") {
      img = pasImage;
    }
    else {
      img = this.state.linkimg + this.props.idee[4]
    }
        
    return(
        <div className="content">
          <div className="idee-css">
            <div class="jumbotron hoverable p-20 bg-white" style={{marginTop:"1%"}}>
              <div class="row" style={{width:"90%",marginLeft:"5%"}}>
                <div class="col-md-4 offset-md-1 mx-3 my-3">
                  <div class="view overlay">
                    <img src={img} class="img-fluid" alt="idee"></img>
                  </div>
                </div>
                <div class="col-md-7 text-md-left ml-3 mt-3 ">
                  <h4 class="h4 mb-4">{this.props.idee[0]}</h4>
                  <p class="text-justify" style={{whiteSpace: "pre-line"}}>{this.props.idee[1]}</p>
                  <p class="font-weight-normal"><strong>{this.props.idee[2]}</strong> {this.props.idee[3]}</p>
                <Link to="/participer_idee" class="btn btn-primary">Participer à l'idée</Link>
                </div>
              </div>
            </div>
          </div>
          <FloatingButton/>
        </div>
      )
    }   
};
const mapStateToProps = (state) => {
    return {
      logged: state.saveUser.logged,
      idee: state.saveIdee.idee
    }
  }
export default connect(mapStateToProps)(article)