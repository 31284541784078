import React from'react';
import './Navbar.css';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { gapi } from 'gapi-script'

const SPREADSHEETS_ID = "12A7ifFUZUuw3Bw1sQvUnM_enV5vg_QEv91Rp3iUxZHM";
class menu extends React.Component {
    constructor() {
        super()
          this.listProjet = this.listProjet.bind(this)
          this.listEtude = this.listEtude.bind(this)
          this.listRealisation = this.listRealisation.bind(this);
        }

    listProjet() {
        gapi.client.sheets.spreadsheets.values.get({
        spreadsheetId: SPREADSHEETS_ID,
        range: 'Appel a projet!A3:F100'
       }).then((response) => {
        var result = response.result;
        const action = { type: "SAVE_PROJET", value:result.values};
        this.props.dispatch(action); 
       });

       gapi.client.sheets.spreadsheets.values.get({
        spreadsheetId: SPREADSHEETS_ID,
        range: 'Appel a projet!B2:B2'
       }).then((response) => {
        var result = response.result;
        const action = { type: "SAVE_PROJET_PHRASE", value:result.values};
        this.props.dispatch(action); 
       });
      }

      listEtude() {
        gapi.client.sheets.spreadsheets.values.get({
        spreadsheetId: SPREADSHEETS_ID,
        range: 'Etude!A3:F100'
       }).then((response) => {
        var result = response.result;
        const action = { type: "SAVE_ETUDE", value:result.values};
        this.props.dispatch(action); 
       });
       gapi.client.sheets.spreadsheets.values.get({
        spreadsheetId: SPREADSHEETS_ID,
        range: 'Etude!B2:B2'
       }).then((response) => {
        var result = response.result;
        const action = { type: "SAVE_ETUDE_PHRASE", value:result.values};
        this.props.dispatch(action); 
       });
      }
      
      listRealisation() {
        gapi.client.sheets.spreadsheets.values.get({
        spreadsheetId: SPREADSHEETS_ID,
        range: 'Realisation!A2:F100'
       }).then((response) => {
        var result = response.result;
        const action = { type: "SAVE_REALISATION", value:result.values};
        this.props.dispatch(action); 
       });
      }

render() {
  let menuClasses = ['menu-list'];
    if(this.props.show) {
        menuClasses= 'menu-list open';
    }
return (
    <nav className={menuClasses}>
        <ul>
            <li><Link to="/">Accueil</Link></li>
            <li><Link to="/liste_projet" onClick={this.listProjet}>Appel à projet</Link></li>
            <li><Link to="/etude" onClick={this.listEtude}>Etude</Link></li>
            <li><Link to="/realisation" onClick={this.listRealisation}>En cours de réalisation</Link></li>
        </ul>
    </nav>
    )
}
};
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps)(menu)