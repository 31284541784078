const initialState = { actualite: [] }
function saveActualite (state = initialState, action) {
  let nextState
  switch (action.type) {
    case 'SAVE_ACTUALITE':
      nextState = {
        ...state,
        actualite: action.value
    }
    return nextState
   
  default:
    return state
  }
}
export default saveActualite