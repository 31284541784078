import { combineReducers } from 'redux'
import saveUser from './userloginReducer'
import saveIdee from './ideeReducer'
import saveEtude from './etudeReducer'
import saveRealisation from './saveRealisation'
import saveArticle from './articleReducer'
import saveProjet from './projetReducer'
import saveArticleProjet from './saveArticleProjet'
import saveActualite from './actualiteReducer'

const rootReducer = combineReducers({
    saveUser,
    saveIdee,
    saveEtude,
    saveRealisation,
    saveActualite,
    saveArticle,
    saveProjet,
    saveArticleProjet
});

export default rootReducer;