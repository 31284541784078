import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import pasImage from './pasImage.jpg';
import "./Appel.css";
import FloatingButton from "../Ajouter_idee/FloatingButton";

class Projet extends React.Component{
  constructor (props) {
		super (props)
		  this.state = {
            linkimg:"https://drive.google.com/uc?export=view&id=",
            img:""
          }
  }
    
render() {
    if(this.props.logged !== true) {
      return (<Redirect to="/connexion" />);
    }  
    
    let img;
    if(this.props.articleProjet[4] === undefined || this.props.articleProjet[4] === "") {
      img = pasImage;
    }
    else {
      img = this.state.linkimg + this.props.articleProjet[4]
    }
    let fichier;
    if(this.props.articleProjet[5] !== undefined) {
      fichier= <a href={this.props.articleProjet[5]} target="_blanck">Télécharger le projet</a>
    }
   
    return(
        <div className="content">
          <div className="projet-css">
            <div class="jumbotron hoverable p-20 bg-white">
              <div class="row" style={{width:"90%",marginLeft:"5%"}}>
                <div class="col-md-4 offset-md-1 mx-3 my-3 ">
                  <div class="view overlay">
                    <img src={img} class="img-fluid" alt="projet"></img>
                  </div>
                </div>
                <div class="col-md-7 text-md-left ml-3 mt-3 ">
                  <h4 class="h4 mb-4">{this.props.articleProjet[0]}</h4>
                  <p class="text-justify" style={{whiteSpace: "pre-line"}}>{this.props.articleProjet[1]}</p>
                  <p class="font-weight-normal"><strong>{this.props.articleProjet[2]}</strong> {this.props.articleProjet[3]}</p>
                  {fichier}<br/>
                 <Link to="/participer_projet" class="btn btn-primary">Participer au projet</Link>
                </div>
              </div>
            </div>
          </div>
          <FloatingButton/>
        </div>
        )
    }   
};
const mapStateToProps = (state) => {
    return {
      logged: state.saveUser.logged,
      articleProjet: state.saveArticleProjet.articleProjet,
    }
  }
export default connect(mapStateToProps)(Projet)