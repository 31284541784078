const initialState = { article: [] }
function saveArticle  (state = initialState, action) {
  let nextState
  switch (action.type) {
    case 'SAVE_ARTICLE':
      nextState = {
        ...state,
        article: action.value
    }
    return nextState
  default:
    return state
  }
}
export default saveArticle