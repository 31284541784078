import React from "react";
import "./Footer.css";
import 'bootstrap/dist/css/bootstrap.min.css';
class footerReact extends React.Component {
render() {
return(
<footer className="footer">
  <div class="erilia-footer-wrapper"> 
    <div class="erilia-footer-left">
      <p>Eriliab: La boîte à idées digitale des collaborateurs d'Erilia</p>
    </div>
      <div class="erilia-footer-right">
	      <p><a href="http://www.erilia.fr" target="_blank" rel="noopener noreferrer" className="footer-text">Erilia 2021</a></p>
      </div>		
  </div>
</footer>
    )
  }
};
export default footerReact