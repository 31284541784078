import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Redirect, Link } from "react-router-dom";
import { connect } from 'react-redux';
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import pasImage from './pasImage.jpg';
import Card from 'react-bootstrap/Card';
import "./Appel.css"
import FloatingButton from "../Ajouter_idee/FloatingButton"

class AppelProjet extends React.Component{
    constructor() {
        super() 
          this.gotoidee = this.gotoidee.bind(this);
            this.state = {
              linkimg:"https://drive.google.com/uc?export=view&id=",
              img:""
            }
        }
gotoidee (e)  {
  const action = { type: "SAVE_ARTICLE_PROJET", value:this.props.projet[e.target.id]};
  this.props.dispatch(action);
}
render() {
  if(this.props.logged !== true) {
    return (<Redirect to="/connexion" />);
  } 
  let data;
  if(this.props.projet !== undefined) {
                
      data = this.props.projet.map((list,idIdee) => { 
        let img;
        if(list[4] === undefined || list[4] === "") {
          img = pasImage;
        }
        else {
          img = this.state.linkimg + list[4]
        }
      return (
        <Col className="position">
          <Card>
            <Card.Header style={{backgroundColor: "rgb(8, 102, 102)", color: "white", fontWeight: "bold", textAlign: "center"}}>{list[0]}</Card.Header>
              <Card.Img top width="100%" src={img}/>
                <Card.Body >
                  <Card.Text>{list[2]}</Card.Text>
                  <Card.Text>{list[3]}</Card.Text>
                    <Link to="/projet">
                      <Button type="submit" id={idIdee} onClick={this.gotoidee} variant="link">En savoir plus</Button>
                    </Link>
                </Card.Body>
          </Card>
        </Col>
        )
      })
    
}
else {
    data =  <Col className="position">
              <Card>
                <Card.Header style={{backgroundColor: "rgb(8, 102, 102)", color: "white", fontWeight: "bold", textAlign: "center"}}>Aucun projet en cours</Card.Header>
                  <Card.Img top width="100%" src={pasImage}/>
                </Card>
            </Col>
}
  return(  
    <div className="content"> 
      <div className="projet">
        <h1>Appel à projet</h1>
        <p className="projet-listP">{this.props.projetPhrase}</p>
            <Container fluid>
              <Row lg="5" md="1" sm="1" xs="1" className="row justify-content-md-center">
                {data}
              </Row>
            </Container>
          <FloatingButton/>
      </div>
    </div> 
    )
  }
};
const mapStateToProps = (state) => {
  return {
    logged: state.saveUser.logged,
    projet: state.saveProjet.projet,
    projetPhrase: state.saveProjet.projetPhrase,
    articleProjet: state.saveArticleProjet.articleProjet
    }
  }   
export default connect(mapStateToProps)(AppelProjet)