import React from "react";
import "./Accueil.css";
import projet from './projet.jpg';
import actu from './actu.jpg';
import idee2 from './idee2.jpg';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import FloatingButton from "../Ajouter_idee/FloatingButton"
import { gapi } from 'gapi-script'

const SPREADSHEETS_ID = "12A7ifFUZUuw3Bw1sQvUnM_enV5vg_QEv91Rp3iUxZHM";

class accueil extends React.Component {
  constructor() {
    super()
      this.articleAccueil = this.articleAccueil.bind(this)
      this.listProjet = this.listProjet.bind(this)
      this.listEtude = this.listEtude.bind(this)
    }

  articleAccueil() {
    gapi.client.sheets.spreadsheets.values.get({
    spreadsheetId: SPREADSHEETS_ID,
    range: 'Actualite!A2:F100'
  }).then((response) => {
    var result = response.result;
    const action = { type: "SAVE_ACTUALITE", value:result.values};
    this.props.dispatch(action); 
    });
  }

listProjet() {
  gapi.client.sheets.spreadsheets.values.get({
  spreadsheetId: SPREADSHEETS_ID,
  range: 'Appel a projet!A3:F100'
 }).then((response) => {
  var result = response.result;
  const action = { type: "SAVE_PROJET", value:result.values};
  this.props.dispatch(action); 
 });

 gapi.client.sheets.spreadsheets.values.get({
  spreadsheetId: SPREADSHEETS_ID,
  range: 'Appel a projet!B2:B2'
 }).then((response) => {
  var result = response.result;
  const action = { type: "SAVE_PROJET_PHRASE", value:result.values};
  this.props.dispatch(action); 
 });
}

listEtude() {
  gapi.client.sheets.spreadsheets.values.get({
  spreadsheetId: SPREADSHEETS_ID,
  range: 'Etude!A3:F100'
 }).then((response) => {
  var result = response.result;
  const action = { type: "SAVE_ETUDE", value:result.values};
  this.props.dispatch(action); 
 });

 gapi.client.sheets.spreadsheets.values.get({
  spreadsheetId: SPREADSHEETS_ID,
  range: 'Etude!B2:B2'
 }).then((response) => {
  var result = response.result;
  const action = { type: "SAVE_ETUDE_PHRASE", value:result.values};
  this.props.dispatch(action); 
 });
}

render() {
  if(this.props.logged !== true) {
  return (<Redirect to="/connexion" />);
}
return(
<div className="content">
          <Container fluid>  
            <Row lg="5" md="1" sm="1" xs="1" className="row justify-content-md-center">          
              <Col className="accueil-list">
                <Card>
                  <Link to="/actualite" style={{textDecoration: "none"}} onClick={this.articleAccueil}>
                <Card.Header style={{backgroundColor: "rgb(8, 102, 102)", color: "white", fontWeight: "bold"}}>ACTUALITES</Card.Header>
                    <Card.Img top width="100%" src={actu} alt="imgactu"/>
                      <Card.Body >
                          <Card.Text style={{color: "black"}} >
                              L'actualité du lab.
                          </Card.Text>
                      </Card.Body>
                     </Link> 
                </Card>
              </Col>

              <Col className="accueil-list">
                <Card>
                 <Link to="/liste_projet" style={{textDecoration: "none"}} onClick={this.listProjet}>
                <Card.Header style={{backgroundColor: "rgb(8, 102, 102)", color: "white", fontWeight: "bold"}}>APPEL A PROJET</Card.Header>
                  <Card.Img top width="100%" src={projet} alt="imglab"/>
                    <Card.Body >
                        <Card.Text style={{color: "black"}}>
                            Liste des projets en cours.
                        </Card.Text>
                    </Card.Body>
                    </Link>
                </Card>
              </Col>

              <Col className="accueil-list">
                <Card>
                   <Link to="/etude" style={{textDecoration: "none"}} onClick={this.listEtude}>
                <Card.Header style={{backgroundColor: "rgb(8, 102, 102)", color: "white", fontWeight: "bold"}}>LES IDEES DES COLLABORATEURS</Card.Header>
                  <Card.Img top width="100%" src={idee2} alt="imglab"/>
                    <Card.Body >
                        <Card.Text style={{color: "black"}}>
                            Retrouver les idées de nos collaborateurs.
                        </Card.Text>
                    </Card.Body>
                        </Link>
                  </Card>
              </Col>
            </Row>
          </Container> 
        <FloatingButton/>
</div>
    )
  }
};
const mapStateToProps = (state) => {
  return {
    logged: state.saveUser.logged,
  }
}
export default connect(mapStateToProps)(accueil)