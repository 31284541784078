import React, { Component } from 'react';
import "./FloatingButton.css"
import { Link } from "react-router-dom";

class FloatingButton extends Component {
    render() {
        return (
            <div>
                <Link to="/ajouter_idee" className="floating-btn">J'ai une idée !</Link>
            </div>         
        );
    }
}
export default FloatingButton;